import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import SubmitButton from '~/components/SubmitButton'
import TextField from '~/components/TextField'
import Toast from '~/components/Toast'
import { AuthContext } from '~/context/auth'
import Heading from '../Heading'
import ParagraphHTML from '../ParagraphHTML'

/**
 * UnverifiedUser
 */

const UnverifiedUser = ({ user }) => {
  // console.log('user', user)
  const { t } = useTranslation()
  const { unverifiedUser } = useContext(AuthContext)
  const [error, setError] = useState()
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      email: user.email,
      token: user.token,
      first_name: user.first_name,
      last_name: '',
      company_fantasy: user.company_fantasy,
      company_role: user.company_role,
    },
  })

  const handleSignup = useCallback(
    async ({ email, token, first_name, last_name, company_fantasy, company_role }) => {
      try {
        // console.log('UPDATE', email, token, first_name, last_name, company_fantasy, company_role)
        await unverifiedUser(email, token, first_name, last_name, company_fantasy, company_role)
      } catch (error) {
        setError({ text: error.response.data.message, layout: 'red' })
      }
    },
    [unverifiedUser]
  )

  return (
    <div className="relative">
      {!isEmpty(error) && <Toast message={error} layout="red" />}
      <div className="min-h-screen flex justify-center items-center py-12 bg-gray-50 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <Heading level={1} size="xl" className="mt-6">
            {t('guest:unverifiedUser.title')}
          </Heading>
          <ParagraphHTML className="mt-4">{t('guest:unverifiedUser.subtitle').replace('MAIL', '')}</ParagraphHTML>
          <br></br>
          <ParagraphHTML className="mt-4">{t('guest:unverifiedUser.subtitle2')}</ParagraphHTML>
          <form className="mt-8" onSubmit={handleSubmit(handleSignup)}>
            <input type="hidden" name="verifyUser" value="true" />
            <div className="hidden">
              <TextField label="" name="email" type="text" register={register} />
              <TextField label="" name="token" type="text" register={register} />
            </div>

            {/* <div className="mt-6">
              <SubmitButton
                text={t('guest:unverifiedUser.submit')}
                isSubmitting={formState.isSubmitting}
                width="100%"
              />
            </div> */}
          </form>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

UnverifiedUser.propTypes = {
  user: PropTypes.object.isRequired,
}

/**
 * Exports
 */

export default UnverifiedUser
