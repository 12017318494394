import React from 'react'
import { isEmpty } from 'lodash'

import { useVods } from '~/api'
import Heading from '~/components/Heading'
import PageContainer from '~/components/layout/PageContainer'
import Paragraph from '~/components/Paragraph'
import VODCard from '~/components/vod/VODCard'
import { useAuth } from '~/context/auth'
import { getConfig } from '~/lib/config'

/**
 * VOD
 */

const VOD = () => {
  const { grouped } = useVods()
  const { user } = useAuth()

  let renderGroup = grouped => {
    const keys = Object.keys(grouped)

    return (
      <>
        {isEmpty(keys) && (
          <div className="mb-4">
            {getConfig('videos_title_no_items', user) && (
              <Heading level={3} size="md" className="mb-2">
                {getConfig('videos_title_no_items', user)}
              </Heading>
            )}
            {getConfig('videos_msg_no_items', user) && (
              <Paragraph className="mt-6 xl:mt-8">{getConfig('videos_msg_no_items', user)}</Paragraph>
            )}
          </div>
        )}
        {keys.map(key => {
          return (
            <div key={key} className="mb-6">
              <div className="flex items-center mb-4">
                <Heading level={3} size="md" className="mb-2">
                  {key}
                </Heading>
              </div>
              <ul className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                {!isEmpty(grouped[key]) &&
                  grouped[key].map(vod => {
                    return <VODCard vod={vod} key={vod.id}></VODCard>
                  })}
              </ul>
            </div>
          )
        })}
      </>
    )
  }

  return <PageContainer>{renderGroup(grouped)}</PageContainer>
}

/**
 * Exports
 */

export default VOD
