import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { includes } from 'lodash'

import { Routes } from '~/constants/routes'

const LOCAL_STORAGE_KEY = 'eventcast_accept_cookies'

/**
 * Helpers
 */

const setToken = token => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, token)
}

const getToken = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEY)
}

/**
 * Header
 */

const Cookies = () => {
  const [isHide, setHide] = useState(!!getToken())

  const handleAcceptPress = useCallback(() => {
    setToken('true')
    setHide(true)
  }, [])

  const handleDeclinePress = useCallback(() => {
    setToken('false')
    setHide(true)
  }, [])

  const handleHidePress = useCallback(() => {
    setHide(true)
  }, [])

  if (process.env.REACT_APP_SHOW_COOKIES === '0') {
    return null
  }
  // Not show on
  if (isHide) {
    return null
  }

  return (
    <div className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-end sm:justify-start">
      {/* <!--
        Notification panel, show/hide based on alert state.
    
        Entering: "transform ease-out duration-300 transition"
          From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          To: "translate-y-0 opacity-100 sm:translate-x-0"
        Leaving: "transition ease-in duration-100"
          From: "opacity-100"
          To: "opacity-0"
      --> */}
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">¿Podemos almacenar cookies?</p>
              <p className="mt-1 text-sm text-gray-500">
                Estas se utilizarán para potenciar las funcionalidades de la aplicación.
              </p>
              <div className="mt-4 flex">
                <button
                  type="button"
                  onClick={handleAcceptPress}
                  className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Aceptar
                </button>
                <button
                  type="button"
                  onClick={handleDeclinePress}
                  className="ml-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Declinar
                </button>
              </div>
            </div>
            <div className="ml-4 flex-shrink-0 flex" onClick={handleHidePress}>
              <button className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Cerrar</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

export default Cookies
