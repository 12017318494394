import { Routes } from '~/constants/routes'

export default {
  header: {
    title: 'Eventos Mondelez',
    dropdown: {
      profile: 'Perfil',
      chat: 'Chat',
      signOut: 'Cerrar sesión',
    },
    section: {
      [Routes.HOME]: 'Home',
      [Routes.PLENARY]: 'Vivo',
      [Routes.SESSIONS]: 'Sesiones',
      [Routes.EXPO]: 'Sponsors',
      [Routes.VOD]: 'Videos',
      [Routes.PROFILE]: 'Tu Perfil',
      [Routes.SUPPORT]: 'Mesa de Ayuda',
    },
  },
  nav: {
    home: 'HOME',
    plenaria: 'VIVO',
    sesiones: 'SESIONES',
    expo: 'EXPO',
    videos: 'VIDEOS',
    live: 'VIVO',
  },
  sidebar: {
    chat: {
      title: 'Muro',
      publicMessage: {
        title: 'Este es un chat público visible para todos',
        imageAlt: 'Público',
      },
      privateMessage: {
        title: 'Los mensajes de este chat son privados',
        imageAlt: 'Privado',
      },
      invitationMessage: {
        appSystemName: 'Organización',
        title: ' desea invitarte a esta conversación',
        deny: 'Denegar',
        accept: 'Aceptar',
      },
      error: {
        description: 'Hubo un error al cargar el muro',
        retry: 'Reintentar',
      },
    },
    inbox: {
      title: 'Chat',
    },
    people: {
      title: 'Participantes',
    },
    support: {
      title: 'Chat de soporte',
    },
  },
  guest: {
    unsupported: {
      description:
        'Algunas funcionalidades de la plataforma no están soportadas por la versión actual de tu navegador.',
      recommendation: 'Te recomendamos que te tomes unos minutos para actualizarlo a algunos de los siguientes:',
      chrome: 'Google Chrome',
      firefox: 'Mozilla Firefox',
      safari: 'Safari',
    },
    login: {
      title: 'Te damos la bienvenida',
      subtitle: 'Por favor iniciá sesión',
      emailLabel: 'Correo electrónico',
      passwordLabel: 'Contraseña',
      forgotPassword: '¿Olvidaste tu contraseña?',
      submit: 'Ingresar',
      signup: '¿No tenés una cuenta?',
    },
    signup: {
      title: 'Bienvenido. Por favor registrese',
      emailLabel: 'Correo electrónico',
      firstNameLabel: 'Nombre',
      lastNameLabel: 'Apellido',
      idLabel: 'DNI',
      zipLabel: 'Dirección Postal',
      phoneLabel: 'Teléfono',
      companyLabel: 'Entidad',
      companyRoleLabel: 'Zona / Región',
      passwordLabel: 'Crear Contraseña',
      submit: 'Registrarse',
      login: '¿Ya tenés una cuenta?',
    },
    updateP: {
      title: 'Completá tus datos',
      firstNameLabel: 'Nombre',
      lastNameLabel: 'Apellido',
      companyLabel: 'Entidad',
      companyRoleLabel: 'Zona / Región',
      submit: 'Actualizar',
    },
    unverifiedUser: {
      title: 'Bienvenido/a!',
      subtitle:
        'Gracias por unirte. Hemos enviado un mail a tu cuenta para que completes el registro. En caso de no recibirlo por favor verificá tu carpeta de correo no deseado.',
      subtitle2: '',
      submit: 'Reenviar correo de verificación',
    },
    verify: {
      title: 'Verificando correo electrónico',
      subtitle: 'Estamos procesando tu solicitud',
    },
    forgot: {
      title: 'Obtener nueva contraseña',
      subtitle: 'Ingresa tu usuario o tu correo electrónico.',
      email: 'Tu usuario o cuenta de email',
      submit: 'Continuar',
    },
    recovery: {
      title: 'Modificación de contraseña',
      subtitle: 'Vas a elegir una nueva contraseña de acceso',
      password1: 'Nueva contraseña',
      password2: 'Nueva contraseña',
      submit: 'Cambiar mi contraseña',
    },
    limit: {
      title: 'Limite de conexiones',
      subtitle:
        'Has alcanzado el limite maximo de conexiones concurrentes. Puedes comunicarte con soporte por cualquier inconveniente',
      click: 'Ok',
    },
  },
  home: {
    program: 'Programa',
    library: 'Video Disponible',
    help: '¿En qué podemos ayudarte?',
    learn_platform: '¿Querés conocer la plataforma?',
  },
  msg: {
    mobileAlert:
      'Recomendamos conectarte a través de un dispositivo desktop para acceder a todas las funcionalidades y disfrutar de esta experiencia completa',
  },
  expo: {
    title: 'Expo',
  },
  mainStage: {
    reactions: {
      remainingTime: 'Tiempo restante',
      clap: 'Aplaudir',
      dislike: 'No me gusta',
      askQuestion: 'Preguntar',
      questionLabel: 'Hacele una pregunta al orador',
      closeModalAlt: 'Cerrar',
      sendMessageAlt: 'Enviar mensaje',
      successQuestion: 'El mensaje fue enviado con éxito',
    },
    languageSelector: {
      spanish: 'Español',
      english: 'Inglés',
    },
  },
  offlineAgenda: {
    remaining: 'Faltan {{time}} para que comience este evento',
  },
  sessions: {
    title: 'Sesiones',
    alert: 'Registrate ya en estas sesiones para poder participar. Los cupos son limitados. ¡Apurate!',
    suscribedSessions: 'Sesiones inscriptas',
    upcomingSessions: 'Próximas sesiones',
    alreadyRegisteredBlock: 'Ya estás registrado en otra sesión simultánea',
    cardButtons: {
      finished: 'Finalizado',
      enter: 'Ingresá',
      full: 'Cupo Completo',
      registered: 'Estás inscripto',
      register: 'Inscribite',
      cancel: 'Cancelá',
    },
    detail: {
      buttonRegister: 'Registrar',
      buttonEnded: 'Finalizada',
      buttonUnsubscribe: 'Cancelar Registro',
      buttonQuotaFull: 'Cupo Completo',
      buttonAlreadyStart: 'Ingresar',
    },
  },
  library: {
    title: 'Librería',
  },
  support: {
    title: 'Comunicate con nuestra mesa de ayuda',
    faq: 'Preguntas Frecuentes',
    chevronAlt: 'Pregunta',
    phone: '+54 9 221 593-1604',
    hours: ' (18hs a 22hs)',
    // whatsapp: '+54 9 11 0129-9218',
    email: 'info@bitbangfest.com',
  },
  profile: {
    name: 'Apellidos y nombre',
    companyName: 'Rol',
    position: 'Apodo',
    companyLabel: 'Entidad',
    product: 'Dirección Postal',
    nationalId: 'DNI',
    companyRoleLabel: 'Zona / Región',
    phone: 'Teléfono',
    inboxActive: 'Modo oculto',
    inboxActiveExplained: 'No participar del chat ni aparecer como participante.',
    pushActive: 'Notificaciones Web',
    closeSession: 'Cerrar sesión',
    messageInbox: 'Modo oculto modificado exitosamente',
    messageImage: 'Imagen modificada correctamente',
  },
}
