import React from 'react'
import { isIE } from 'react-device-detect'

import UnsupportedBrowser from '~/components/guest/UnsupportedBrowser'
import VerifyUser from '~/components/guest/VerifyUser'
import GuestLayout from '~/components/layout/GuestLayout'

/**
 * RecoverPassword
 */

const RecoverPassword = () => {
  return (
    <GuestLayout>
      {isIE && <UnsupportedBrowser />}
      {!isIE && <VerifyUser />}
    </GuestLayout>
  )
}

/**
 * Exports
 */

export default RecoverPassword
