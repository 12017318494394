import React from 'react'
import reactHtmlParser from 'react-html-parser'
import classNames from 'classnames'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import twemoji from 'twemoji'

import ToolTip from '../ToolTip'

import './style.scss'

/**
 * SenderMessageBubble
 */

const SenderMessageBubble = props => {
  const message = Object.assign({}, props.message, { messageFrom: 'sender' })

  const showTooltip = !props.widgetconfig || (props.widgetconfig && props.widgetconfig['threaded-chats'])

  const emojiParsedMessage = twemoji.parse(props.message.text, { folder: 'svg', ext: '.svg' })
  const parsedMessage = reactHtmlParser(emojiParsedMessage)

  const emojiMessage = parsedMessage.filter(message => message instanceof Object && message.type === 'img')

  const messageClassName = classNames({
    'chat-txt-msg': true,
    size1x: parsedMessage.length === emojiMessage.length && emojiMessage.length === 1,
    size2x: parsedMessage.length === emojiMessage.length && emojiMessage.length === 2,
    size3x: parsedMessage.length === emojiMessage.length && emojiMessage.length > 2,
  })

  const isSending = isNil(props.message.sentAt)
  const timestamp = new Date(props.message.sentAt * 1000).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  return (
    <>
      <div className="cc1-chat-win-sndr-row clearfix">
        <div className="cc1-chat-win-msg-block">
          <div className="flex flex-row justify-end">
            <div className="cc1-chat-win-msg-time-wrap">
              <span className="cc1-chat-win-timestamp">
                {!isSending && timestamp}
                {isSending && <img className="loading-message" src="/images/icon-clock.png" alt="Cargando mensaje" />}
              </span>
            </div>
          </div>
          {showTooltip && (
            <ToolTip message={message} action="viewMessageThread" actionGenerated={props.actionGenerated} />
          )}
          <div className="cc1-chat-win-sndr-msg-wrap bg-primary">
            <p className={messageClassName}>{parsedMessage}</p>
          </div>
        </div>
      </div>
      <style jsx>{`
        .loading-message {
          width: 10px;
          height: 10px;
        }
      `}</style>
    </>
  )
}

/**
 * PropTypes
 */

SenderMessageBubble.propTypes = {
  message: PropTypes.object,
  widgetconfig: PropTypes.object,
  actionGenerated: PropTypes.func,
}

/**
 * Exports
 */

export default SenderMessageBubble
