import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import NavItem from '~/components/layout/NavItem'
import LiveLabel from '~/components/LiveLabel'
import { Routes } from '~/constants/routes'
import { AuthContext } from '~/context/auth'
import { useSocketManager } from '~/context/socketManager'
import { getConfig } from '~/lib/config'
import { eventFolder } from '~/lib/multievent'

/**
 * Nav
 */

const Nav = ({ isOpen, onItemClick }) => {
  const { mainStageLive } = useSocketManager()
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  return (
    <>
      <div className="nav absolute md:static flex flex-col justify-between items-center p-2 border-r-2 bg-white overflow-hidden">
        <ul>
          <NavItem onClick={onItemClick} imgSrc={eventFolder('icon-home.svg')} to={Routes.HOME} label={t('nav:home')} />
          <div className="relative">
            {getConfig('nav_vivo', user) !== '' && (
              <NavItem
                onClick={onItemClick}
                imgSrc={eventFolder('icon-plenary.svg')}
                to={Routes.PLENARY}
                label={getConfig('nav_vivo', user)}
              />
            )}
            {!!(mainStageLive && mainStageLive.online) && <LiveLabel />}
          </div>
          {getConfig('nav_sessiones', user) !== '' && (
            <NavItem
              onClick={onItemClick}
              imgSrc={eventFolder('icon-sessions.svg')}
              to={Routes.SESSIONS}
              label={getConfig('nav_sessiones', user)}
            />
          )}
          {getConfig('nav_expo', user) !== '' && (
            <NavItem
              onClick={onItemClick}
              imgSrc={eventFolder('icon-expo.svg')}
              to={Routes.EXPO}
              label={getConfig('nav_expo', user)}
              className="text-6xs"
            />
          )}
          {getConfig('nav_videos', user) !== '' && (
            <NavItem
              onClick={onItemClick}
              imgSrc={eventFolder('icon-vod.svg')}
              to={Routes.VOD}
              label={getConfig('nav_videos', user)}
            />
          )}
        </ul>
        {getConfig('nav_support', user) !== '' && (
          <NavItem onClick={onItemClick} imgSrc={eventFolder('icon-help.svg')} to={Routes.SUPPORT} />
        )}
      </div>
      <style jsx>{`
        .nav {
          width: 80px;
          left: ${isOpen ? '0' : '-80px'};
          transition: left 0.2s ease-in-out;
        }

        @media only screen and (max-width: 768px) {
          .nav {
            height: calc(100% - 64px);
            z-index: 10;
          }
        }
      `}</style>
    </>
  )
}

/**
 * PropTypes
 */

Nav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default Nav
