import React from 'react'
import PropTypes from 'prop-types'

/**
 * ParagraphHTML
 */

const ParagraphHTML = ({ children, className }) => {
  return <p className={`text-base text-paragraphHTML leading-6 ${className}`}>{children}</p>
}

/**
 * PropTYpes
 */

ParagraphHTML.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

ParagraphHTML.defaultProps = {
  className: '',
}

/**
 * Exports
 */

export default ParagraphHTML
