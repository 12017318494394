import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import SubmitButton from '~/components/SubmitButton'
import TextField from '~/components/TextField'
import Toast from '~/components/Toast'
import { AuthContext } from '~/context/auth'
import Heading from '../Heading'
import InvalidCUIT from './msg/InvalidCUIT'

/**
 * Sign _ Up
 */

function isValidCUITCUIL(cuit) {
  if (cuit.length !== 11) {
    return false
  }

  const [checkDigit, ...rest] = cuit
    .split('')
    .map(Number)
    .reverse()

  const total = rest.reduce((acc, cur, index) => acc + cur * (2 + (index % 6)), 0)

  const mod11 = 11 - (total % 11)

  if (mod11 === 11) {
    return checkDigit === 0
  }

  if (mod11 === 10) {
    return false
  }

  return checkDigit === mod11
}

const SignUp = ({ onLoginClick, onUnVerifiedUserClick, onThanksPageEnable }) => {
  const { t } = useTranslation()
  const { signup } = useContext(AuthContext)
  const location = useLocation()

  const [error, setError] = useState()
  const [showInvalidCUIT, setInvalidCUIT] = useState(false)

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      company_fantasy: '',
      company_role: '',
      product: '',
      national_id: '',
      cuit: '',
      phone: '',
      address: '',
      zip: '',
      email: '',
      password: 'nopassword',
      externalId: 'App Sign Up',
    },
  })

  const handleSignup = useCallback(
    async ({
      first_name,
      last_name,
      product,
      national_id,
      cuit,
      company_fantasy,
      company_role,
      phone,
      address,
      zip,
      email,
      password,
      externalId,
    }) => {
      if (cuit && !isValidCUITCUIL(cuit)) {
        setInvalidCUIT(true)

        return
      }

      try {
        let user = await signup(
          first_name,
          last_name,
          product,
          national_id,
          cuit,
          location.search,
          company_fantasy,
          company_role,
          phone,
          address,
          zip,
          email,
          password,
          externalId
        )
        if (user && user.verified === 0) {
          onUnVerifiedUserClick(user)
        }
        if (process.env.REACT_APP_CONFIRM_SIGNUP === 1) {
          onThanksPageEnable(user)
        }
        onThanksPageEnable(user)
      } catch (error) {
        setError({ text: error.response.data.message, layout: 'red' })
      }
    },
    [location.search, onThanksPageEnable, onUnVerifiedUserClick, signup]
  )

  let fields = [
    { var: 'REACT_APP_AUTH_FIELD_FIRSTNAME', field: 'first_name', type: 'text' },
    { var: 'REACT_APP_AUTH_FIELD_LASTNAME', field: 'last_name', type: 'text' },
    { var: 'REACT_APP_AUTH_FIELD_COMPANY', field: 'company_fantasy', type: 'text' },
    { var: 'REACT_APP_AUTH_FIELD_COMPANYROLE', field: 'company_role', type: 'text' },
    { var: 'REACT_APP_AUTH_FIELD_NATIONALID', field: 'national_id', type: 'number' },
    { var: 'REACT_APP_AUTH_FIELD_ADDRESS', field: 'address', type: 'text' },
    { var: 'REACT_APP_AUTH_FIELD_PHONE', field: 'phone', type: 'text' },
    { var: 'REACT_APP_AUTH_FIELD_ZIP', field: 'zip', type: 'text' },
    { var: 'REACT_APP_AUTH_FIELD_PRODUCT', field: 'product', type: 'text' },
  ]

  return (
    <div className="relative">
      {!isEmpty(error) && <Toast message={error} layout="red" />}
      <div className="min-h-screen flex justify-center items-center py-12 bg-gray-50 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <Heading level={1} size="xl" className="mt-6">
            {t('guest:signup.title')}
          </Heading>
          <form className="mt-8" onSubmit={handleSubmit(handleSignup)}>
            <input type="hidden" name="remember" value="true" />
            {fields.map((field, key) => {
              // eslint-disable-next-line react/jsx-key
              if (!isEmpty(process.env[field.var])) {
                return (
                  <div key={key} className="mb-4 number">
                    <TextField
                      label={process.env[field.var]}
                      name={field.field}
                      type={field.type}
                      register={register}
                    />
                  </div>
                )
              }
            })}
            {!isEmpty(process.env.REACT_APP_AUTH_FIELD_CUIT) && (
              <div className="mb-4">
                <TextField
                  label={process.env.REACT_APP_AUTH_FIELD_CUIT}
                  name="cuit"
                  type="number"
                  pattern="/^[0-9]{3,11}$/"
                  register={register}
                />
              </div>
            )}
            <div className="mb-4">
              <TextField
                label={process.env.REACT_APP_AUTH_FIELD_EMAIL || 'E-mail'}
                name="email"
                type="email"
                register={register}
              />
            </div>
            <div className="mb-4 hidden">
              <TextField label={t('guest:signup.passwordLabel')} name="password" type="password" register={register} />
            </div>
            {/* <div className="mb-4">
              <div className="mt-1 relative rounded-md">
                <select
                  id="company_fantasy"
                  name="company_fantasy"
                  ref={register}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option>USA</option>
                  <option selected>Canada</option>
                  <option>EU</option>
                </select>
              </div>
            </div> */}
            {/* <div>
              <div className="mt-8 flex rounded-md font-medium text-sm leading-5">
                <input
                  name="privacidad"
                  type="checkbox"
                  required
                  className="form-checkbox w-6 inline-block py-3 sm:text-base sm:leading-5"
                  ref={register}
                />{' '}
                <span className="ml-3 mt-1">
                  Acepto los{' '}
                  <a href="/privacidad" target="_blank" rel="noopener noreferrer" className="underline">
                    términos y condiciones & la política de privacidad
                  </a>
                </span>
              </div>
            </div> */}
            <InvalidCUIT
              showMessage={showInvalidCUIT}
              onClose={() => {
                setInvalidCUIT(false)
              }}
            />
            <div className="mt-6 flex">
              <div className="text-sm leading-5" onClick={onLoginClick}>
                <p className="cursor-pointer font-bold font-medium text-paragraph focus:outline-none focus:underline">
                  {t('guest:signup.login')}
                </p>
              </div>
            </div>
            <div className="mt-6">
              <SubmitButton text={t('guest:signup.submit')} isSubmitting={formState.isSubmitting} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

SignUp.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onUnVerifiedUserClick: PropTypes.func.isRequired,
  onThanksPageEnable: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default SignUp
