import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { uploadProfile } from '~/api'
import PageContainer from '~/components/layout/PageContainer'
import Success from '~/components/msg/Success'
import ImageProfile from '~/components/profile/ImageProfile'
import LogoutButton from '~/components/profile/LogoutButton'
import SliderButton from '~/components/profile/SliderButton'
import TextField from '~/components/profile/TextField'
import { useAuth } from '~/context/auth'
import { useSocketManager } from '~/context/socketManager'
import { bugsnag } from '~/lib/bugsnag'
import { showConfig } from '~/lib/config'
import { getUserPhotoUrl } from '~/lib/helpers'

/**
 * Profile
 */

const Profile = () => {
  const { t } = useTranslation()
  const { user, mutateUser, logout, loginToCometChat } = useAuth()
  const { socketEmit } = useSocketManager()
  const [inboxActive, setUploadInboxActive] = useState(!user.inbox_active)
  const [success, setSuccess] = useState('')

  async function updateUser(query, callback) {
    try {
      await uploadProfile(user.id, query)
      mutateUser(query)
    } catch (error) {
      bugsnag.notify(error)
    }

    if (callback) {
      callback()
    }
  }

  function logoutCall() {
    socketEmit('disconnectAll')
    logout()
  }

  function mutateUserHandler(obj) {
    setSuccess(t('profile:messageImage'))
    mutateUser(obj)
  }
  const userPhotoUrl = getUserPhotoUrl(user)

  let fields = [
    { var: 'REACT_APP_AUTH_FIELD_FIRSTNAME', field: 'first_name' },
    { var: 'REACT_APP_AUTH_FIELD_LASTNAME', field: 'last_name' },
    { var: 'REACT_APP_AUTH_FIELD_COMPANY', field: 'company_fantasy' },
    { var: 'REACT_APP_AUTH_FIELD_COMPANYROLE', field: 'company_role' },
    { var: 'REACT_APP_AUTH_FIELD_NATIONALID', field: 'national_id' },
    { var: 'REACT_APP_AUTH_FIELD_ADDRESS', field: 'address' },
    { var: 'REACT_APP_AUTH_FIELD_PHONE', field: 'phone' },
    { var: 'REACT_APP_AUTH_FIELD_ZIP', field: 'zip' },
    { var: 'REACT_APP_AUTH_FIELD_PRODUCT', field: 'product' },
  ]

  return (
    <PageContainer className="relative hiddenScroll">
      <div className="max-w-xl m-auto">
        <ImageProfile id={user.id} photoUrlUser={userPhotoUrl} mutateUser={mutateUserHandler} />
        <Success
          title={success}
          onClose={() => {
            setSuccess('')
          }}
        />
        <form action="#" method="POST" className="mt-12">
          {fields.map(field => {
            // eslint-disable-next-line react/jsx-key
            if (!isEmpty(process.env[field.var])) {
              return (
                <div className="mt-6">
                  <TextField
                    label={process.env[field.var]}
                    defaultValue={user[field.field]}
                    disabled={false}
                    onChange={(value, callback) => {
                      let answer = []
                      answer[field.field] = value
                      updateUser(answer, callback)
                    }}
                  />
                </div>
                // <div className="mb-4">
                //   <TextField label={process.env[field.var]} name={field.field} type="text" register={register} />
                // </div>
              )
            }
          })}

          {/* <div>
          <TextField
            label={t('profile:name')}
            defaultValue={user.full_name}
            disabled={false}
            onChange={(full_name, callback) => {
              updateUser({ full_name }, callback)
            }}
          />
        </div>
        <div className="mt-6">
          <TextField
            label={t('profile:nationalId')}
            defaultValue={user.national_id}
            disabled={false}
            onChange={(national_id, callback) => {
              updateUser({ national_id }, callback)
            }}
          />
        </div>
        <div className="mt-6">
          <TextField
            label={t('profile:product')}
            defaultValue={user.product}
            onChange={(product, callback) => {
              updateUser({ product }, callback)
            }}
          />
        </div> */}
          {showConfig('general_chat_enable', user) && (
            <SliderButton
              label={t('profile:inboxActive')}
              secondLabel={t('profile:inboxActiveExplained')}
              defaultValue={inboxActive}
              onClick={inbox_active => {
                setUploadInboxActive(inbox_active)
                updateUser({ inbox_active: !inbox_active })
                setSuccess(t('profile:messageInbox'))
                if (!inbox_active) {
                  loginToCometChat()
                }
              }}
            />
          )}
        </form>
        <LogoutButton label={t('profile:closeSession')} logout={logoutCall} />
      </div>
    </PageContainer>
  )
}

/**
 * Exports
 */

export default Profile
