import React from 'react'
import { isIE } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

import UnsupportedBrowser from '~/components/guest/UnsupportedBrowser'
import VerifyUser from '~/components/guest/VerifyUser'
import GuestLayout from '~/components/layout/GuestLayout'
/**
 * RecoverPassword
 */

const RecoverPassword = () => {
  const history = useHistory()
  history.push('/')

  return (
    <GuestLayout>
      {isIE && <UnsupportedBrowser />}
      {!isIE && <VerifyUser />}
    </GuestLayout>
  )
}

/**
 * Exports
 */

export default RecoverPassword
