import { useSocketManager } from '~/context/socketManager'

export const useSidebarMessageQuery = () => {
  const { sidebarMessage = {}, chatEnable = true } = useSocketManager()

  return {
    data: sidebarMessage,
    chat: chatEnable,
  }
}
