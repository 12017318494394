import React from 'react'
import { useTranslation } from 'react-i18next'

import { eventFolder } from '~/lib/multievent'

/**
 * PublicChatMessage
 */

const PublicChatMessage = () => {
  const { t } = useTranslation()

  return (
    <div className="relative flex flex-row items-center justify-center h-10 bg-backgroundPrimary">
      <img className="mr-2" alt={t('sidebar:chat.publicMessage.imageAlt')} src={eventFolder('icon-world.svg')} />
      <p className="text-sm text-primary">{t('sidebar:chat.publicMessage.title')}</p>
    </div>
  )
}

/**
 * Exports
 */

export default PublicChatMessage
