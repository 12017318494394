import React, { useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'

import { sendQuestion, sendReactions, useMainStage } from '~/api'
import OffLineAgenda from '~/components/OffLineAgenda'
import RemainingTime from '~/components/RemainingTime'
import { useAuth } from '~/context/auth'
import { bugsnag } from '~/lib/bugsnag'
import { showConfig } from '~/lib/config'
import { eventFolder } from '~/lib/multievent'
// import * as animationDataClap from '~/lotties/mainstage_clap.json'
// import * as animationDataDisLike from '~/lotties/mainstage_dislike.json'
import * as animationDataClap from '~/themes/bitbang2020/lotties/mainstage_clap.json'
import * as animationDataDisLike from '~/themes/bitbang2020/lotties/mainstage_dislike.json'
import QuestionModal from './QuestionModal'
import QuestionSuccessModal from './QuestionSuccessModal'

/**
 * ReactionsBox
 */

const ReactionsBox = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { agenda } = useMainStage(process.env.REACT_APP_EVENT_ID)
  const [showQuestionModal, setShowQuestionModal] = useState(false)
  const [showSuccessMessage, setShowClearSuccessMessage] = useState(false)
  const [lottieClapStopped, setLottieClapStopped] = useState(true)
  const [lottieDisLikeStopped, setLottieDisLikeStopped] = useState(true)

  const handleCloseQuestionModal = useCallback(() => {
    setShowQuestionModal(false)
    ReactGA.event({ category: 'MainStage', action: 'SubmitQuestion', label: 'Close' })
  }, [])

  const handleClapClick = useCallback(() => {
    setLottieClapStopped(false)

    try {
      sendReactions(agenda.id, 'clap')
    } catch (error) {
      bugsnag.notify(error)
    }

    setTimeout(() => {
      setLottieClapStopped(true)
    }, 1500)
    ReactGA.event({ category: 'MainStage', action: 'ReactionBox', label: 'Clap' })
  }, [agenda.id])

  const handleDislikeClick = useCallback(() => {
    setLottieDisLikeStopped(false)

    try {
      sendReactions(agenda.id, 'dislike')
    } catch (error) {
      bugsnag.notify(error)
    }

    setTimeout(() => {
      setLottieClapStopped(true)
    }, 1000)
    ReactGA.event({ category: 'MainStage', action: 'ReactionBox', label: 'DisLike' })
  }, [agenda.id])

  const handleAskQuestionClick = useCallback(() => {
    setShowQuestionModal(true)
    ReactGA.event({ category: 'MainStage', action: 'SubmitQuestion', label: 'Open' })
  }, [])

  const handleSubmitQuestion = useCallback(
    values => {
      try {
        sendQuestion(agenda.id, values.question)
      } catch (error) {
        bugsnag.notify(error)
      }

      setShowQuestionModal(false)
      setShowClearSuccessMessage(true)
      ReactGA.event({ category: 'MainStage', action: 'SubmitQuestion', label: 'Sent' })
    },
    [agenda.id]
  )

  const handleClearSuccessMessage = useCallback(() => {
    setShowClearSuccessMessage(false)
  }, [])

  useEffect(() => {
    let timeout = null

    if (showSuccessMessage) {
      timeout = setTimeout(handleClearSuccessMessage, 3000)
    }

    return () => clearTimeout(timeout)
  }, [handleClearSuccessMessage, showSuccessMessage])

  return (
    <div className="md:block w-full bg-white border-b border-gray-200 absolute bottom-0 inset-x-0 h-24">
      <div className="flex h-full max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        {!agenda.online && <OffLineAgenda start_date={agenda.start_date} />}
        {agenda.online === 1 && (
          <>
            {showConfig('stage_box_showtime', user) && (
              <div className="self-center w-1/3">
                <RemainingTime start={agenda.start_date} end={agenda.end_date} />
              </div>
            )}
            <div className="w-1/3 flex flex-row items-center justify-center">
              {showConfig('stage_box_showclap', user) && (
                <div className="mr-8 cursor-pointer max-w-15" onClick={handleClapClick}>
                  <Lottie
                    isClickToPauseDisabled
                    isStopped={lottieClapStopped}
                    width="100%"
                    options={{
                      loop: false,
                      autoplay: false,
                      animationData: animationDataClap.default,
                    }}
                  />
                </div>
              )}
              {showConfig('stage_box_showdislike', user) && (
                <div className="mr-8 cursor-pointer max-w-15" onClick={handleDislikeClick}>
                  <Lottie
                    isClickToPauseDisabled
                    isStopped={lottieDisLikeStopped}
                    width="100%"
                    options={{
                      loop: false,
                      autoplay: false,
                      animationData: animationDataDisLike.default,
                    }}
                  />
                </div>
              )}
              {showConfig('stage_box_showquestion', user) && (
                <div className="cursor-pointer">
                  <img
                    src={eventFolder('icon-toolbox-question.svg')}
                    alt={t('mainStage:reactions.askQuestion')}
                    onClick={handleAskQuestionClick}
                  />
                </div>
              )}
            </div>
            <div className="w-1/3" />
          </>
        )}
      </div>
      {showQuestionModal && <QuestionModal onSubmit={handleSubmitQuestion} onClose={handleCloseQuestionModal} />}
      {showSuccessMessage && <QuestionSuccessModal onClose={handleClearSuccessMessage} />}
    </div>
  )
}

/**
 * Exports
 */

export default ReactionsBox
