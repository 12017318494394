import React from 'react'
import PropTypes from 'prop-types'

import { getUnreadMessagesText } from '~/lib/helpers'

import './style.scss'

/**
 * BadgeCount
 */

const BadgeCount = ({ count }) => {
  if (!count) {
    return null
  }

  return <span className="chat-ppl-listitem-msg-cnt bg-primary">{getUnreadMessagesText(count)}</span>
}

/**
 * PropTypes
 */

BadgeCount.propTypes = {
  count: PropTypes.number,
}

/**
 * Exports
 */
export default BadgeCount
