import React, { useCallback, useRef, useState } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Heading from '~/components/Heading'

/**
 * FAQCard
 */

const FAQCard = ({ faq }) => {
  const { question, answer } = faq
  const [isOpen, setOpen] = useState(false)
  const [height, setHeight] = useState(false)
  const content = useRef(null)
  const { t } = useTranslation()

  const handleClick = useCallback(() => {
    setHeight(content.current.scrollHeight)
    setOpen(!isOpen)
    ReactGA.event({ category: 'Support', action: 'FAQCards', label: isOpen + '_' + question.substring(0, 20) })
  }, [isOpen, question])

  return (
    <div className="border-b last:border-0 cursor-pointer" onClick={handleClick}>
      <div className="flex flex-row justify-between items-center">
        <Heading level={2} size="sm" className="bg-white py-4 text-lg leading-5 font-bold text-heading tracking-wide">
          {question}
        </Heading>
        <div className="icon-container mr-1 flex items-center justify-end">
          <img className="icon" src="/images/icon-chevron.svg" alt={t('support:chevronAlt')} />
        </div>
      </div>
      <p
        ref={content}
        className="content text-base leading-5 text-paragraph pr-8"
        dangerouslySetInnerHTML={{ __html: answer }}
      ></p>
      <style jsx>{`
        .content {
          height: ${isOpen ? `${height + 20}px` : '0'};
          overflow: hidden;
          transition: height 0.5s ease-in-out;
        }

        .icon-container {
          width: 40px;
          padding-left: 10px;
        }

        .icon {
          width: 16px;
          height: 16px;
          transform: rotate(${isOpen ? 0 : '-180deg'});
          transition: transform 0.5s ease-in-out;
        }
      `}</style>
    </div>
  )
}

/**
 * PropTypes
 */

FAQCard.propTypes = {
  faq: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
  }),
}

/**
 * Exports
 */

export default FAQCard
