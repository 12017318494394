import React from 'react'
import { filter, find, findIndex, isEqual } from 'lodash'
import PropTypes from 'prop-types'

import { eventFolder } from '~/lib/multievent'
import ConversationView from '../ConversationView'

import './style.scss'

/**
 * CometChatConversationList
 */

class CometChatConversationList extends React.Component {
  state = {
    loading: false,
    isCreateGroupModalOpen: false,
  }

  componentDidUpdate(prevProps) {
    const { conversations, setNewConversations, groupToDelete, groupToUpdate, groupToLeave } = this.props

    if (prevProps.groupToDelete.guid !== groupToDelete.guid) {
      const newConversationList = filter(conversations, item => item.conversationId !== `group_${groupToDelete.guid}`)
      setNewConversations(newConversationList)
    }

    if (!isEqual(prevProps.groupToUpdate, groupToUpdate)) {
      const newConversationList = [...conversations]
      const index = findIndex(newConversationList, item => item.conversationId === `group_${groupToUpdate.guid}`)

      if (index > -1) {
        newConversationList[index].conversationWith = groupToUpdate
        setNewConversations(newConversationList)
      }
    }

    if (!isEqual(prevProps.groupToLeave, groupToLeave)) {
      const newConversationList = filter(conversations, item => item.conversationId !== `group_${groupToLeave.guid}`)
      setNewConversations(newConversationList)
    }

    // if user is blocked/unblocked, update conversationlist in state
    if (
      prevProps.item &&
      Object.keys(prevProps.item).length &&
      prevProps.item.uid === this.props.item.uid &&
      prevProps.item.blockedByMe !== this.props.item.blockedByMe
    ) {
      const conversationlist = [...conversations]
      // search for user
      let convKey = conversationlist.findIndex(
        c => c.conversationType === 'user' && c.conversationWith.uid === this.props.item.uid
      )
      if (convKey > -1) {
        conversationlist.splice(convKey, 1)
        setNewConversations(conversationlist)
      }
    }
  }

  handleScroll = e => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === Math.round(e.currentTarget.clientHeight)
    if (bottom) {
      this.props.getConversations()
    }
  }

  // updating unread message count to zero
  handleClick = conversation => {
    const { onItemClick, conversations, setNewConversations, setSelectedConversation } = this.props

    if (!onItemClick) {
      return
    }

    const conversationlist = [...conversations]
    const conversationIndex = findIndex(conversationlist, item => item.conversationId === conversation.conversationId)
    const conversationObj = find(conversationlist, item => item.conversationId === conversation.conversationId)
    const newConversationObj = { ...conversationObj, unreadMessageCount: 0 }

    conversationlist.splice(conversationIndex, 1, newConversationObj)
    setNewConversations(conversationlist)
    setSelectedConversation(newConversationObj)

    onItemClick(newConversationObj.conversationWith, newConversationObj.conversationType)
  }

  handleMenuClose = () => {
    const { actionGenerated } = this.props

    if (!actionGenerated) {
      return false
    }

    actionGenerated('closeMenuClicked')
  }

  render() {
    const filteredConversationList = filter(
      this.props.conversations,
      item =>
        item.conversationId !== 'group_plenaria' &&
        item.conversationId.indexOf('group_session_') !== 0 &&
        item.conversationId.indexOf('coloquio_soporte') < 0
    )

    // const loadingClassName = classNames({
    //   'loading-text': true,
    //   hide: !this.state.loading,
    // })

    return (
      <>
        {/* <div className="ccl-left-panel-head-wrap">
          <h4 className="ccl-left-panel-head-ttl">Chats</h4>
          <div className="cc1-left-panel-close" onClick={this.handleMenuClose}></div>
        </div> */}
        {/* <div className={loadingClassName}>Cargando...</div> */}
        <div className="chat-ppl-list-ext-wrap" onScroll={this.handleScroll}>
          <div className="chat-listitem" onClick={this.props.onNewGroupClick}>
            <div className="chat-thumbnail-wrap">
              <img alt="Nuevo grupo" src={eventFolder('icon-add.svg')} />
            </div>
            <div className="chat-listitem-dtls">
              <div className="new-group-button chat-listitem-name">Nuevo grupo</div>
            </div>
          </div>
          {filteredConversationList.map((conversation, key) => {
            return (
              <ConversationView
                key={key}
                config={this.props.config}
                conversationKey={key}
                conversation={conversation}
                handleClick={this.handleClick}
              />
            )
          })}
        </div>
      </>
    )
  }
}

/**
 * PropTypes
 */

CometChatConversationList.propTypes = {
  onItemClick: PropTypes.func,
  actionGenerated: PropTypes.func,
  config: PropTypes.object,
  groupToDelete: PropTypes.object,
  groupToUpdate: PropTypes.object,
  groupToLeave: PropTypes.object,
  item: PropTypes.object,
  conversations: PropTypes.array,
  setNewConversations: PropTypes.func,
  getConversations: PropTypes.func,
  setSelectedConversation: PropTypes.func,
  onNewGroupClick: PropTypes.func,
}

/**
 * Exports
 */

export default CometChatConversationList
