import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import SubmitButton from '~/components/SubmitButton'
import TextField from '~/components/TextField'
import Toast from '~/components/Toast'
import { AuthContext } from '~/context/auth'
import Heading from '../Heading'

/**
 * UpdateProfile
 */

const UpdateProfile = ({ user }) => {
  console.log('user', user)
  const { t } = useTranslation()
  const { updateProfile } = useContext(AuthContext)
  const [error, setError] = useState()
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      email: user.email,
      token: user.token,
      first_name: user.first_name,
      last_name: '',
      company_fantasy: user.company_fantasy,
      company_role: user.company_role,
    },
  })

  const handleSignup = useCallback(
    async ({ email, token, first_name, last_name, company_fantasy, company_role }) => {
      try {
        console.log('UPDATE', email, token, first_name, last_name, company_fantasy, company_role)
        await updateProfile(email, token, first_name, last_name, company_fantasy, company_role)
      } catch (error) {
        setError({ text: error.response.data.message, layout: 'red' })
      }
    },
    [updateProfile]
  )

  return (
    <div className="relative">
      {!isEmpty(error) && <Toast message={error} layout="red" />}
      <div className="min-h-screen flex justify-center items-center py-12 bg-gray-50 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <Heading level={1} size="xl" className="mt-6">
            {t('guest:updateP.title')}
          </Heading>
          <form className="mt-8" onSubmit={handleSubmit(handleSignup)}>
            <input type="hidden" name="remember" value="true" />
            <div className="hidden">
              <TextField label="" name="email" type="text" register={register} />
              <TextField label="" name="token" type="text" register={register} />
            </div>

            <div className="mb-4">
              <TextField label={t('guest:updateP.firstNameLabel')} name="first_name" type="text" register={register} />
            </div>
            <div className="mb-4">
              <TextField label={t('guest:updateP.lastNameLabel')} name="last_name" type="text" register={register} />
            </div>
            <div className="mb-4">
              <TextField
                label={t('guest:updateP.companyLabel')}
                name="company_fantasy"
                type="text"
                register={register}
              />
            </div>
            {/* <div className="mb-4">
              <TextField
                label={t('guest:updateP.companyRoleLabel')}
                name="company_role"
                type="text"
                register={register}
              />
            </div> */}
            <div className="mt-6">
              <SubmitButton text={t('guest:updateP.submit')} isSubmitting={formState.isSubmitting} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

UpdateProfile.propTypes = {
  user: PropTypes.object.isRequired,
}

/**
 * Exports
 */

export default UpdateProfile
