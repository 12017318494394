import React, { useCallback, useMemo } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { isEmpty, orderBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import { subscribeSessionUser } from '~/api'
import CardBtnCancel from '~/components/CardBtnCancel'
import CardBtnRegister from '~/components/CardBtnRegister'
import HostNames from '~/components/global/HostsNames'
import Heading from '~/components/Heading'
import Paragraph from '~/components/Paragraph'
import { sessionPropType } from '~/prop-types'
import SessionCardBottomBar from './SessionCardBottomBar'
import SessionInfo from './SessionInfo'
/**
 * SessionCard
 */

const SessionCard = ({ session, groupBlocked }) => {
  const { id, Hosts, open_date, end_date, title, subtitle, quota, enrolled, Users } = session
  const { t } = useTranslation()
  const isRegistered = !isEmpty(Users)
  const history = useHistory()

  const hasEnded = useMemo(() => moment().isAfter(end_date), [end_date])
  const isOpened = Users && Users.length > 0 && open_date && moment().isAfter(open_date)
  const isFull = enrolled >= quota

  const handleUnsubscribe = useCallback(async () => {
    await subscribeSessionUser(id, 'unsubscribe')
    ReactGA.event({ category: 'Session', action: 'unsubscribe', label: title })
  }, [title, id])

  const handleSubscribe = useCallback(async () => {
    if (isRegistered || hasEnded) {
      return
    }

    await subscribeSessionUser(id, 'subscribe')
    ReactGA.event({ category: 'Session', action: 'subscribe', label: title })
  }, [title, hasEnded, id, isRegistered])

  const handleCardClick = useCallback(() => {
    if (isOpened && !hasEnded) {
      history.push(`/sesiones/${id}`)
    }
  }, [hasEnded, history, id, isOpened])

  let sortedHost = orderBy(Hosts, ['last_name'], ['asc'])
  sortedHost = orderBy(sortedHost, ['moderator'], ['desc'])

  return (
    <>
      <li className="card h-full col-span-1 bg-white rounded-lg shadow relative overflow-hidden">
        <div
          onClick={handleCardClick}
          className={`${isOpened && !hasEnded ? 'cursor-pointer' : 'cursor-default'} h-full`}
        >
          <div className="flex flex-col justify-between h-full p-6">
            <div>
              <div className="flex justify-between mb-2">
                <div className="flex relative z-0 overflow-hidden mb-2">
                  {!isEmpty(sortedHost) &&
                    sortedHost.map((host, index) => {
                      return (
                        <img
                          key={host.id}
                          className={`relative z-${50 - index * 10} ${
                            index > 0 ? '-ml-4' : ''
                          }  inline-block h-8 w-8 box-content rounded-full object-cover text-white border-2 border-white shadow-solid`}
                          src={host.image}
                          alt={host.name}
                        />
                      )
                    })}
                </div>
                <SessionInfo session={session} />
              </div>
              <div className="mt-4 text-left sm:mt-0">
                <Heading level={3} size="sm" className="md:text-2xl md:leading-7 md:pr-6 tracking-wide">
                  {title}
                </Heading>
                {subtitle && (
                  <div className="mt-2">
                    <Paragraph>{subtitle}</Paragraph>
                  </div>
                )}
                {Hosts && (
                  <div className="mt-2">
                    <Paragraph>
                      <HostNames sortedHost={sortedHost} />
                    </Paragraph>
                  </div>
                )}
              </div>
            </div>
            {!isRegistered && groupBlocked.indexOf(session.group) >= 0 ? (
              <div className="actions flex justify-between text-sm text-red-600 mt-10">
                {t('sessions:alreadyRegisteredBlock')}
              </div>
            ) : (
              <div className="actions flex justify-between mt-10">
                <div className="flex items-center">
                  <CardBtnCancel onClick={handleUnsubscribe} show={isRegistered && !isOpened} />
                </div>
                <div className="flex items-center">
                  <CardBtnRegister
                    onClick={handleSubscribe}
                    isRegistered={isRegistered}
                    isOpened={isOpened}
                    isFull={isFull}
                    hasEnded={hasEnded}
                  />
                </div>
              </div>
            )}
          </div>

          <SessionCardBottomBar show={isRegistered} />
        </div>
      </li>
      <style jsx>{`
        .card {
          min-height: 264px;
        }

        .actions {
          height: 40px;
        }
      `}</style>
    </>
  )
}

/**
 * PropTypes
 */

SessionCard.propTypes = {
  session: sessionPropType.isRequired,
  groupBlocked: PropTypes.array.isRequired,
}

/**
 * Exports
 */

export default SessionCard
