import React from 'react'
import { isEmpty } from 'lodash'

import { useExpos } from '~/api'
import Heading from '~/components/Heading'
import ExpoCard from '~/components/expo/ExpoCard'
import PageContainer from '~/components/layout/PageContainer'

/**
 * Expo
 */

const Expo = () => {
  const { grouped } = useExpos()

  let renderGroup = grouped => {
    const keys = Object.keys(grouped)

    return (
      <>
        {keys.map(key => {
          return (
            <div key={key} className="mb-6">
              <div className="flex items-center mb-4">
                <Heading level={3} size="md" className="mb-2">
                  {key}
                </Heading>
              </div>
              <ul className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                {!isEmpty(grouped[key]) &&
                  grouped[key].map((expo, index) => {
                    return <ExpoCard expo={expo} key={index} />
                  })}
              </ul>
            </div>
          )
        })}
      </>
    )
  }
  return <PageContainer>{!isEmpty(grouped) && renderGroup(grouped)}</PageContainer>
}

/**
 * Exports
 */

export default Expo
