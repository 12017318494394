import React from 'react'
import PropTypes from 'prop-types'

import Hero from '~/components/guest/Hero'

/**
 * GuestLayout
 */

const GuestLayout = ({ children }) => {
  return (
    <div className="flex flex-col lg:flex-row h-screen relative">
      <Hero />
      <div className="w-full lg:w-1/2 overflow-y-auto">{children}</div>
      {process.env.REACT_APP_ENV !== 'production' && (
        <div className="absolute right-0 bottom-0 text-xs mr-1">
          {process.env.REACT_APP_ENV} - {process.env.REACT_APP_BUILD_SHA}
        </div>
      )}
    </div>
  )
}

/**
 * PropTypes
 */

GuestLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

/**
 * Exports
 */

export default GuestLayout
