import React from 'react'
import ReactGA from 'react-ga'

import { expoPropType } from '~/prop-types'

/**
 * ExpoCard
 */

const ExpoCard = ({ expo }) => {
  const { banner, web, brand } = expo

  return (
    <>
      {web && (
        <a
          href={web}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event({ category: 'Link', action: 'Expo', label: brand })
          }}
        >
          <li className={`card bg-white ${web ? `cursor-pointer` : `cursor-default`} rounded-lg p-6 shadow`}>
            <div className="flex justify-center h-full">
              <img className="h-full object-contain" src={banner} alt={brand} />
            </div>
          </li>
        </a>
      )}
      {!web && (
        <li className={`card bg-white ${web ? `cursor-pointer` : `cursor-default`} rounded-lg p-6 shadow`}>
          <div className="flex justify-center h-full">
            <img className="h-full object-contain" src={banner} alt={brand} />
          </div>
        </li>
      )}
      <style jsx>{`
        .card {
          height: 151px;
        }
      `}</style>
    </>
  )
}

/**
 * PropTypes
 */

ExpoCard.propTypes = {
  expo: expoPropType.isRequired,
}

/**
 * Exports
 */

export default ExpoCard
