import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { isEmpty, orderBy } from 'lodash'
import moment from 'moment-timezone'

import Heading from '~/components/Heading'
import { agendaPropType } from '~/prop-types'
import Paragraph from '../Paragraph'

/**
 * Helpers
 */

const getHostNames = sortedHost => {
  if (isEmpty(sortedHost)) {
    return ''
  }

  // Split Host and Moderators
  let sortedHostMode = sortedHost.filter(obj => obj.moderator)
  sortedHost = sortedHost.filter(obj => !obj.moderator)

  // Make Strings
  let stringHosts = sortedHost.map(e => e.name).join(', ')
  let stringHostsMode = sortedHostMode.map(e => e.name).join(', ')

  // Hosts String
  let lastComma = stringHosts.lastIndexOf(',')
  if (lastComma > 0) {
    let separator = 'y'
    if (stringHosts.substring(lastComma + 2, lastComma + 3) === 'I') {
      separator = 'e'
    }
    stringHosts = stringHosts.substring(0, lastComma) + ' ' + separator + stringHosts.substring(lastComma + 1)
  }
  if (stringHosts.length > 0) {
    stringHosts = stringHosts + '.'
  }

  // Moderators String
  lastComma = stringHostsMode.lastIndexOf(',')
  if (lastComma > 0) {
    stringHostsMode = stringHostsMode.substring(0, lastComma) + ' y' + stringHostsMode.substring(lastComma + 1)
  }
  if (sortedHostMode.length > 1) {
    stringHostsMode = ' Moderan: ' + stringHostsMode + '.'
  } else if (sortedHostMode.length > 0) {
    stringHostsMode = ' Modera: ' + stringHostsMode + '.'
  }

  return stringHosts + stringHostsMode
}

const checkTextOpacity = end_date => (moment(end_date) < moment() ? 'text-opacity-50' : '')

const checkAvatarOpacity = end_date => (moment(end_date) < moment() ? 'opacity-50' : '')

/**
 * ProgramAgenda
 */

const ProgramAgenda = ({ agenda }) => {
  const { start_date, end_date, title, Hosts, vod, subtitle } = agenda
  const { t } = useTranslation()

  let sortedHost = orderBy(Hosts, ['last_name'], ['asc'])
  sortedHost = orderBy(sortedHost, ['moderator'], ['desc'])

  const isBreak = title && title.indexOf('Break') === 0 ? 'bg-gray-300 -mx-4 px-4' : ''

  return (
    <div className={`border-b last:border-0 block ${isBreak}`}>
      <div className="flex text-left sm:mt-0 mx-2 py-4">
        <div className="mr-12">
          <Moment format="HH:mm" className={`block text-xl font-bold text-heading ${checkTextOpacity(end_date)}`}>
            {start_date}
          </Moment>
          <Moment format="HH:mm" className={`block text-base text-right text-paragraph ${checkTextOpacity(end_date)}`}>
            {end_date}
          </Moment>
        </div>
        <div className="flex flex-1 flex-col xl:flex-row justify-between">
          <div>
            {/* <Link to={`${open_date ? '/sesiones' : '/plenaria'}`}> */}
            <div className="flex flex-1 flex-col xl:flex-row xl:space-x-4">
              <Heading level={3} size="sm" id="modal-headline" className={`mb-2 xl:mb-2 ${checkTextOpacity(end_date)}`}>
                {title}
              </Heading>
              {vod && (
                <Link to={`/vod/${vod}`} className="mb-2 xl:mb-2">
                  <span className="vod-label flex flex-1 cursor-pointer px-3 py-1 rounded-full text-xs font-medium leading-4 bg-gradient-to-r from-blue-200 to-yellow-400">
                    {t('home:library')}
                  </span>
                </Link>
              )}
            </div>
            {/* </Link> */}
            {!isEmpty(sortedHost) && (
              <Paragraph className={`${checkTextOpacity(end_date)} leading-4 sm:leading-4`}>
                {getHostNames(sortedHost)}
              </Paragraph>
            )}
            {!isEmpty(subtitle) && (
              <Paragraph className={`${checkTextOpacity(end_date)} leading-4 sm:leading-4`}>
                <span dangerouslySetInnerHTML={{ __html: subtitle }} />
              </Paragraph>
            )}
          </div>
          {!isEmpty(sortedHost) && (
            <div className={`flex relative items-center z-0 mt-2 xl:mt-0 xl:ml-2 ${checkAvatarOpacity(end_date)}`}>
              {sortedHost.map((host, index) => {
                return (
                  host.image && (
                    <img
                      key={`img-${host.name}`}
                      className={`relative z-${50 - index * 10} ${
                        index > 0 ? '-ml-3' : ''
                      } inline-block h-8 w-8 md:w-8 md:h-8 xl:w-10 xl:h-10 box-content rounded-full object-cover text-white border-2 border-white shadow-solid`}
                      src={host.image}
                      alt={host.name}
                    />
                  )
                )
              })}
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .vod-label {
          width: 114px;
        }
      `}</style>
    </div>
  )
}

/**
 * PropTypes
 */

ProgramAgenda.propTypes = {
  agenda: agendaPropType.isRequired,
}

/**
 * Exports
 */

export default ProgramAgenda
