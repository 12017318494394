import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import Toast from '~/components/Toast'
import { AuthContext } from '~/context/auth'
import Heading from '../Heading'

/**
 * Recovery
 */

const Recovery = () => {
  const { t } = useTranslation()
  const { loginToken } = useContext(AuthContext)
  const { h } = useParams()
  const [error, setError] = useState()

  useEffect(() => {
    async function fetchData() {
      try {
        await loginToken(h)
      } catch (error) {
        window.scrollTo(0, 0)
        setError({
          text: error.response && error.response.data ? error.response.data.message : error.response,
          layout: 'red',
        })
      }
    }
    fetchData()
  }, [h, loginToken])

  // If you have a user account with us, we've sent you an email with password reset instructions. If the email doesn't show up soon, check your spam folder.
  // app.bugsnag.com/user/password/edit?i=em&m=pr&reset_password_token=Q-8ntz19VYo4Sb8XFPze

  return (
    <div className="relative">
      {!isEmpty(error) && <Toast message={error} layout="red" />}
      <div className="min-h-screen flex pt-16 lg:pt-32 justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <div>
            <Heading level={1} size="xl">
              {t('guest:verify.title')}
            </Heading>
            <Heading level={2} size="sm" className="mt-4">
              {t('guest:verify.subtitle')}
            </Heading>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

export default Recovery
