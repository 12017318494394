import React from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash'

import { useFAQs } from '~/api'
import Heading from '~/components/Heading'
import PageContainer from '~/components/layout/PageContainer'
import FAQCard from '~/components/support/FAQCard'
import { useAuth } from '~/context/auth'
import { getConfig } from '~/lib/config'
import { eventFolder } from '~/lib/multievent'

/**
 * Support
 */

const Support = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { support } = useFAQs(process.env.REACT_APP_EVENT_ID)
  const faqs = get(support, 'faqs.faqs')

  return (
    <PageContainer>
      {(getConfig('support_phone', user) || getConfig('support_email', user)) && (
        <div className="banner flex flex-1 flex-col p-6 bg-cover bg-center bg-white rounded-lg shadow mb-20">
          <Heading level={1} size="md" className="mb-8 text-supportHeading">
            {getConfig('support_title', user)}
          </Heading>
          <div>
            {getConfig('support_phone', user) && (
              <div className="flex flex-row items-center mb-4">
                <img src={eventFolder('icon-phone.svg')} className="mr-2" alt="Teléfono" />
                <a
                  href={`tel:+${getConfig('support_phone', user)}`}
                  onClick={() => {
                    ReactGA.event({ category: 'Support', action: 'Link', label: 'Phone' })
                  }}
                >
                  <span className="text-lg leading-5 font-bold text-supportHeading">
                    {getConfig('support_phone', user)} {getConfig('support_hours', user)}
                  </span>
                </a>
              </div>
            )}
            {getConfig('support_email', user) && (
              <div className="flex flex-row items-center">
                <img src={eventFolder('icon-whatsapp.svg')} className="mr-2" alt="WhatsApp" />
                <a
                  href={`mailto:${getConfig('support_email', user)}`}
                  onClick={() => {
                    ReactGA.event({ category: 'Support', action: 'Link', label: 'Email' })
                  }}
                >
                  <span className="text-lg leading-5 font-bold text-supportHeading">
                    {getConfig('support_email', user)}
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
      )}
      <Heading level={2} size="lg" className="mb-8">
        {t('support:faq')}
      </Heading>
      <div className="p-6 bg-white rounded-lg shadow">
        <ul className="grid grid-cols-1 lg:grid-cols-1">
          {!isEmpty(faqs) &&
            faqs.map((faq, index) => {
              return <FAQCard faq={faq} key={index}></FAQCard>
            })}
        </ul>
      </div>
      <style jsx>{`
        .banner {
          min-height: 335px;
          background-image: url(${getConfig('support_background', user)});
          border-radius: 15px;
        }
      `}</style>
    </PageContainer>
  )
}

/**
 * Exports
 */

export default Support
