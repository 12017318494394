import React, { useContext, useEffect } from 'react'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { BrowserRouter as Router } from 'react-router-dom'

import Routes from '~/components/Routes'
import { AuthContext } from '~/context/auth'

/**
 * App
 */

const App = () => {
  const { initialized } = useContext(AuthContext)

  useEffect(() => {
    ReactGA.initialize('UA-179079405-1')

    const tagManagerArgs = {
      gtmId: 'GTM-MB2VD76',
    }

    if (process.env.REACT_APP_TAG_MANAGER !== '') {
      tagManagerArgs.gtmId = process.env.REACT_APP_TAG_MANAGER
    }

    TagManager.initialize(tagManagerArgs)
  }, [])

  if (!initialized) {
    // TODO: Add loading
    return null
  }

  return (
    <Router>
      <Routes />
    </Router>
  )
}

/**
 * Exports
 */

export default App
